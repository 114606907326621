import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Hidden,
  Button,
  Typography,
  Toolbar,
  AppBar,
  Drawer,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from "assets/Group 620.svg";
import logo2 from "assets/WEARHOUSE.svg";
import Footer from "../Footer";
import { navLinks } from "utils/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    letterSpacing: 8,
  },
  logo: {
    width: 25,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: "20px",
    },
  },
  logo2: {
    width: 150,
    [theme.breakpoints.down("xl")]: { width: 250 },
    [theme.breakpoints.down("lg")]: { width: 200 },
    [theme.breakpoints.down("md")]: { width: 180 },
    [theme.breakpoints.down("sm")]: { width: 170 },
  },
  link: {
    color: "white",
    margin: 20,
    fontSize: 18
  },
  appbar: {
    background: "transparent",
    paddingLeft: "5%",
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    position: "absolute",
  },
  list: {
    width: 170,
  },
}));

function DrawerLink(props) {
  const { children } = props;
  const [match, setMatch] = useState(false);
  return (
    <NavLink
      {...props}
      isActive={(match) => {
        if (match) setMatch(true);
      }}
    >
      {React.cloneElement(children, {
        selected: match,
      })}
    </NavLink>
  );
}

export default function ButtonAppBar({ children }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);
  const history = useHistory();
  useEffect(() => {
    history.listen(() => {
      closeDrawer();
    });
  }, [history]);

  return (
    <div className={classes.root}>
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <List className={classes.list}>
          {navLinks.map((item, i) => (
            <DrawerLink
              activeClassName={classes.activeDrawerLink}
              key={i}
              to={item.to}
            >
              <ListItem button key={i}>
                <ListItemText primary={item.label} />
              </ListItem>
            </DrawerLink>
          ))}
        </List>
      </Drawer>
      <AppBar position="absolute" elevation={0} className={classes.appbar}>
        <Toolbar>
          <Link to="/" style={{ display: "flex" }}>
            <img src={Logo} alt="img" className={classes.logo} />
            <Hidden xsDown>
              <img src={logo2} alt="img" className={classes.logo2} />
            </Hidden>
          </Link>
          <div style={{ flexGrow: 1 }} />
          <Hidden xsDown>
            {navLinks.map((item, i) => (
              <Link key={i} to={item.to} className={classes.link}>
                {item.label}
              </Link>
            ))}
          </Hidden>
          <Hidden smUp>
            <MenuIcon onClick={openDrawer} />
          </Hidden>
        </Toolbar>
      </AppBar>
      {children}
      <Footer />
    </div>
  );
}
