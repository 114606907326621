import { makeStyles } from "@material-ui/core/styles";
import img from "assets/bg.png";

const useStyles = makeStyles((theme) => ({
  hero: {
    height: 500,
    width: "100%",
    backgroundImage: `url(${img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: 'no-repeat',
    zIndex: theme.zIndex.appBar - 2,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("xl")]: {
      height: 750
    },
    [theme.breakpoints.down("lg")]: {
      height: 650
    },
    [theme.breakpoints.down("md")]: {
      height: 550
    },
    [theme.breakpoints.down("sm")]: {
      height: 500,
      paddingTop: 100
    },
    [theme.breakpoints.down("xs")]: {
      height: 700,
      paddingTop: 150,
      backgroundPosition: '-220px 0px',
      backgroundSize: '350%'
    },
  },
  textSection: {
    zIndex: theme.zIndex.appBar - 1,
    padding: "7%",
    color: "white",
    paddingTop: "8%",
    width: "35%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  t1: {
    fontSize: 32,
    fontWeight: 100,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("xl")]: {
      fontSize: 52
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 40
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 38
    },
  },
  t2: {
    font: "normal 44px DM Serif",
    lineHeight: 1,
    [theme.breakpoints.down("xl")]: {
      fontSize: 80,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 50,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 50,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
  },
  subtitle: {
    font: "normal normal 300 15px Lato",
    [theme.breakpoints.down('xl')]: {
      font: "normal normal 300 25px Lato",
    },
    [theme.breakpoints.down('lg')]: {
      font: "normal normal 300 20px Lato",
    },
    [theme.breakpoints.down('md')]: {font: "normal normal 300 18px Lato",},
    // [theme.breakpoints.down('sm')]: {},
  },
  img: {
    width: "50%",
    marginTop: 120,
    [theme.breakpoints.down("xl")]: { width: "40%", marginTop: 120 },
    [theme.breakpoints.down("lg")]: { width: "50%", marginTop: 120 },
    [theme.breakpoints.down("md")]: { width: "55%", marginTop: 70 },
    [theme.breakpoints.down("sm")]: { width: "70%", marginTop: 80 },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      margin: "auto",
      marginTop: 0
    },
  },
  imgSection: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: 20
    },
    textAlign: 'center'
  },
}));

export default useStyles;
