import React from "react";
import routes from "./Router/router";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "Layout";
import { Provider } from "react-redux";
import store from "Store";
import theme from "theme/theme";

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Layout>
              {routes.map((item, i) => (
                <Route key={i} {...item} />
              ))}
            </Layout>
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
}
export default App;
