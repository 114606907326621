import React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import { termsAndCondition } from "utils/termsAndCondition";
import { privacyPolicy } from "utils/privacyPolicy.constant";

const useStyles = makeStyles((theme) => ({
  body: {
    background: "#DBDBDB",
    paddingBottom: 50,
  },
  root: {
    width: "70%",
    margin: "auto",
    padding: 12,
    paddingBottom: 24,
    marginTop: -150,
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("md")]: { width: "80%" },
    [theme.breakpoints.down("sm")]: { width: "85%" },
    [theme.breakpoints.down("xs")]: { width: "90%" },
  },
  blackBox: {
    width: "100%",
    height: 300,
    backgroundImage: "linear-gradient(to bottom, #131B23 , #DBDBDB)",
  },
  text: {
    textAlign: "center",
  },
}));

function TermsAndCondition() {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.blackBox} />
      <Paper className={classes.root}>
        <h1 className={classes.text}>Privacy Policy</h1>
        <p>{privacyPolicy.text1}</p>
        <p>{privacyPolicy.text2}</p>
        <p>{privacyPolicy.text3}</p>
        <p>{privacyPolicy.text4}</p>
        <p>
          <strong>{privacyPolicy.text5}</strong>
        </p>
        <p>{privacyPolicy.text6}</p>
        <p>
          <strong>{privacyPolicy.text7}</strong>
        </p>
        <p>{privacyPolicy.text8}</p>
        <p>
          <strong>{privacyPolicy.text9}</strong>
        </p>
        <p>{privacyPolicy.text10}</p>
        <p>{privacyPolicy.text10}</p>
        <p>
          <strong>Service Providers</strong>
        </p>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>{privacyPolicy.text12}</p>
        <p>
          <strong>Security</strong>
        </p>
        <p>{privacyPolicy.text13}</p>
        <p>
          <strong>Links to Other Sites</strong>
        </p>
        <p>{privacyPolicy.text14}</p>
        <p>
          <strong>Children’s Privacy</strong>
        </p>
        <p>{privacyPolicy.text15}</p>
        <p>
          <strong>Changes to This Privacy Policy</strong>
        </p>
        <p>{privacyPolicy.text16}</p>
        <p>This policy is effective as of 2021-07-02</p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>{privacyPolicy.text17}</p>
      </Paper>
    </div>
  );
}

export default TermsAndCondition;
