import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    paddingLeft: '8%',
    paddingRight: '8%',
    background: "#131B23",
    color: "white",
    paddingBottom: 100,
    [theme.breakpoints.down("xs")]: { 
      padding: '8%',
      paddingBottom: "12%" 
    },
  },
  footerBox1: {
    width: "80%",
    justifyContent: "flex-start",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 40,
    },
  },
  title: {
    letterSpacing: 12,
    fontSize: 14,
  },
  logo: {
    width: 20,
    marginRight: 12,
  },
  input: {
    width: "calc(100% - 16px)",
    background: "white",
    marginBottom: 8,
    border: 0,
    outline: "none",
    borderRadius: 3,
    paddingLeft: 16,
    paddingTop: 14,
    paddingBottom:14,
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 16px)",
      marginBottom: 16
    },
  },
  msginput:{
    height: 80,
    border: 0,
    borderRadius: 3,
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom:9,
    marginBottom: 18,
    width: "calc(100% - 16px)",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 18px)",
      marginBottom: 25
    },
    fontFamily: 'Lato'
  },
  form: {
    margin: "auto",
    marginTop: 30,
    width: "100%",
    [theme.breakpoints.down("xs")]: { 
      marginTop: 12,
      width: "100%", 
      marginBottom: 30 ,
    },
  },
  btn: {
    width: "100%",
    background: "#0BB2B4",
    border: 0,
    borderRadius: 4,
    padding: 12,
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: { width: "100%" },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  contactTitle: { 
    font: " 49px DM Serif Display",
    margin: "20px 0",
  },
  teamTitle: {
    font: " 49px DM Serif Display",
    marginBottom: 20,
  },
  teamImg: {
    borderRadius: 200,
    width: "80%",
  },
  teamName: { fontSize: 16 },
  aboutSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 50,
    },
  },
  aboutusContent: {
    fontSize: 10,
    textAlign: 'justify',
    [theme.breakpoints.up("md")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  email: {
    width: 25,
    [theme.breakpoints.down('xs')]: { width: 35}
  },
  facebook: {
    width: 25,
    [theme.breakpoints.down('xs')]: { width: 35}
  },
  twitter: {
    width: 25,
    [theme.breakpoints.down('xs')]: { width: 35}
  },
  linkedin: {
    width: 25,
    [theme.breakpoints.down('xs')]: { width: 35}
  },
  google: {
    width: 35,
    [theme.breakpoints.down('xs')]: { width: 45}
  },
}));

export default useStyles;
