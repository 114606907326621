import React from "react";
import useStyles from "./style";
import { home } from "utils/constant";
import { Link } from "react-router-dom";
import playStoreImg from "assets/playStoreImg.png";
import appleStoreImg from "assets/appleStoreImg.svg";
import { Grid, Typography } from "@material-ui/core";
import { playstorelink, applestorelink } from "utils/constant";
import Slider from './components/Slider';

function CustomTitle(props) {
  return (
    <div style={{ display: "flex", marginBottom: 12 }}>
      <div className={props.classes.index}>
        <p style={{ textAlign: "center" }}>{props.index + 1}</p>
      </div>
      <p className={props.classes.options}>
        {props.item}
      </p>
    </div>
  );
}

function Features() {
  const { features } = home;
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={5} sm={6} xs={12}>
        <Typography variant="h6" className={classes.title}>
          {features.title}
        </Typography>
        {features.features.map((item, i) => (
          <CustomTitle classes={classes} key={i} item={item} index={i} />
        ))}
      </Grid>
      <Grid item container spacing={3} md={7} sm={6} xs={12}>
        <Slider />
      </Grid>
      <Grid item md={12} sm={12} xs={12}  className={classes.margin} />
      <Grid
        item
        container
        spacing={3}
        md={5}
        sm={5}
        xs={12}
        className={classes.storeBtn}
      >
        <Grid item md={6} xs={6} sm={6}>
          <Link to={playstorelink}>
            <img
              src={playStoreImg}
              className={classes.icon}
              alt="play store img"
            />
          </Link>
        </Grid>
        <Grid item md={6} xs={6} sm={6}>
          <Link to={applestorelink}>
            <img
              src={appleStoreImg}
              className={classes.icon}
              alt="apple store img"
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Features;
