import facebookImg from "assets/facebook.svg";
import twitterImg from "assets/twitter.svg";
import instaImg from "assets/instagram.png";
import youtubeImg from "assets/youtube.png";
import linkedinImg from "assets/linkedin.svg";
import emailImg from "assets/email.png";
import phoneImg from "assets/phone.png";
import anushImg from "assets/Anush.jpeg"
import guraanshImg from "assets/Guraansh.jpg"
import slide1 from "assets/bg2.png";
import slide2 from "assets/bg3.png";
import slide3 from "assets/bg4.png";

export const navLinks = [
  { label: "App", to: "/app" },
  { label: "Reach Us", to: "/react-us" },
];

export const home = {
  hero: {
    t1: "Keep your",
    t2: "Wardrobe",
    t3: "at your",
    t4: "fingertips",
    sub1: "Access your wardrobe anytime, ",
    sub2: "anywhere.Create outfits plan",
    sub3: " trips whenever you want."
  },
  features: {
    title: "Features",
    features: [
      "Create outfits",
      "Plan trips and events",
      "Get recommended outfits",
      "A calendar to remind you",
      "Get and grant access of your wardrobe",
    ],
  },
};

export const playstorelink = "https://play.google.com/store";
export const applestorelink = "https://www.apple.com/in/app-store/";
export const sliders = [
  {
    img: "https://www.apple.com/in/app-store/images/overview/chiclets/hero_14_3__b9gaa036p8pe_large.jpg",
    name: "David",
    description:
      "It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use",
  },
  {
    img: "https://www.apple.com/in/app-store/images/overview/chiclets/hero_14_3__b9gaa036p8pe_large.jpg",
    name: "David",
    description:
      "It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use",
  },
  {
    img: "https://www.apple.com/in/app-store/images/overview/chiclets/hero_14_3__b9gaa036p8pe_large.jpg",
    name: "David",
    description:
      "It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use",
  },
  {
    img: "https://www.apple.com/in/app-store/images/overview/chiclets/hero_14_3__b9gaa036p8pe_large.jpg",
    name: "David",
    description:
      "It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use",
  },
  {
    img: "https://www.apple.com/in/app-store/images/overview/chiclets/hero_14_3__b9gaa036p8pe_large.jpg",
    name: "David",
    description:
      "It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use.It is a nice app to use. saves lot of time and very easy to use",
  },
];

export const team = [
  {
    img: guraanshImg,
    name: "Guraansh Singh",
  },
  {
    img: anushImg,
    name: "Anush Lahoti",
  },
];

export const socialLinks = [
  { to: " https://www.instagram.com/wearhouse.app/", icon: instaImg, name: 'twitter' },
  { to: "https://www.facebook.com/WearHouse-101802418810212", icon: facebookImg, name: 'facebook' },
  { to: "https://www.youtube.com/channel/UCbnMzdC1oaZ-vJh2GunVkHA", icon: youtubeImg, name: 'twitter' },
  { to: "https://www.linkedin.com/company/wearhouse/?viewAsMember=true", icon: linkedinImg, name: 'linkedin' },
  { to: "https://twitter.com/Wearhouse_App", icon: twitterImg, name: 'twitter' },
 

];

export const aboutUs = {
  title: 'About Us',
  content: "Wearhouse by The AnG Company, a lifestyle app by Guraansh Singh & Anush Lahoti, helps you get ready daily, faster than you did before. With our online wardrobe on your mobile screens, you can upload what you own, match your tees and shirts with your lowers and shoes, create outfits, list them according to moods and events, and also schedule them according to the dates you need them on. Add what you're packing in the suitcase on our app, so you know what's the best you can wear on your trip. Pick what you like, put it in your wishlist and add goodies in the gift section. With us, you're always ready."
}

export const contactUs = 
[
  {
    logo: emailImg,
    text: "heangcomapny@gmail.com" 
  },
  {
    logo: phoneImg,
    text: "9833880186" 
  },
]  


export const featureSlides = [
  slide1, slide2, slide3
]
