import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { featureSlides } from "utils/constant";
import { config } from "react-spring";
import { makeStyles, IconButton } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    height: 400,
    display: "flex",
    marginLeft: "auto",
    [theme.breakpoints.down('xl')]: {
      height: 500,
      width: '60%',
      marginRight: 'auto'
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      height: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: '87%',
      height: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 350,
      width: "100%",
      margin: "auto",
    },
  },
  controlls: {
    height: 30,
    width: 30,
    background: "#131B23",
    color: "white",
    marginTop: 180,
    [theme.breakpoints.down('xl')]:{
      marginTop: 240
    },
    [theme.breakpoints.down('lg')]:{
      marginTop: 240
    },
    [theme.breakpoints.down('md')]:{
      marginTop: 180
    },
    [theme.breakpoints.down('sm')]:{
      marginTop: 140
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      marginTop: 150,
    },
    "&:hover": { color: "black" },
  },
}));

function Slider() {
  const classes = useStyles();
  const [slide, setSlide] = useState(0);
  const state = {
    goToSlide: 1,
    offsetRadius: 1.5,
    showNavigation: true,
    config: config.gentle,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const checkSlide = () => {
    if (featureSlides.length - 1 === slide) return true;
    return false;
  };
  const checkSlide2 = () => {
    if (slide === 0) return true;
    return false;
  };
  const movePrev = () => (!checkSlide() ? setSlide(slide + 1) : setSlide(0));
  const moveNext = () =>
    !checkSlide2() ? setSlide(slide - 1) : setSlide(featureSlides.length - 1);
  return (
    <div className={classes.root}>
      <IconButton onClick={moveNext} className={classes.controlls}>
        <ChevronLeft />
      </IconButton>
      <Carousel
        slides={featureSlides.map((item, i) => ({
          key: i,
          content: <img src={item} alt="carousel_image" />,
        }))}
        goToSlide={slide}
        offsetRadius={state.offsetRadius}
        animationConfig={state.config}
        interval={state.autoplaySpeed}
        infiniteLoop={true}
        dynamicHeight={true}
        ref={(slider) => (slider = slider)}
        {...settings}
      ></Carousel>
      <IconButton onClick={movePrev} className={classes.controlls}>
        <ChevronRight />
      </IconButton>
    </div>
  );
}

export default Slider;
