import React from "react";
import Carousel from "react-multi-carousel";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import { sliders } from "utils/constant";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    paddingBottom: "6%",
  },
  card: {
    boxShadow: "0px 3px 15px #0000001F",
    textAlign: "center",
    padding: 18,
    borderRadius: 6,
  },
  cardContainer: {
    padding: 25,
  },
  cardImg: {
    borderRadius: 200,
  },
  cardTxt: {
    color: "#131B23",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: { fontSize: 20 },
    [theme.breakpoints.down("lg")]: { fontSize: 20 },
    [theme.breakpoints.down("md")]: { fontSize: 18 },
    [theme.breakpoints.down("sm")]: { fontSize: 18 },
    [theme.breakpoints.down("xs")]: { fontSize: 16 },
  },
}));

function Slider() {
  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className={classes.root}>
      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        autoPlaySpeed={1000}
        // customTransition=""
        transitionDuration={1000}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {sliders.map((item, i) => (
          <div key={i} className={classes.cardContainer}>
            <div className={classes.card}>
              <img src={item.img} className={classes.cardImg} alt="cardImg" />
              <Typography variant="h6" className={classes.cardTxt}>
                {item.description}
              </Typography>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Slider;
