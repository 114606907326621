import React from "react";
import { Typography, Divider, Grid, Hidden } from "@material-ui/core";
import useStyles from "./styles";
import { home } from "utils/constant";
import bgimg from "assets/bg1.png";

function Hero() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.hero}>
        <div className={classes.textSection}>
          <Typography variant="h6" className={classes.t1}>
            {home.hero.t1}
          </Typography>
          <Typography variant="h6" className={classes.t2}>
            {home.hero.t2}
          </Typography>
          <Typography variant="h6" className={classes.t1}>
            {home.hero.t3}
          </Typography>
          <Typography variant="h6" className={classes.t2}>
            {home.hero.t4}
          </Typography>
          <Typography
            variant="h6"
            style={{ marginTop: 20 }}
            className={classes.subtitle}
          >
            {home.hero.sub1}
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            {home.hero.sub2}
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            {home.hero.sub3}
          </Typography>
          {/* <Hidden xsDown>
          <div
            style={{
              height: 50,
              padding: 0.2,
              background: "white",
              width: 1,
              marginTop: 5,
              marginBottom: 5,
              marginLeft: 5
            }}
          />
          <p
            style={{
              transform: "rotate(-90deg)",
              display: "inline-block",
              marginLeft: -15
            }}
            className={classes.subtitle}
          >
            Scroll
          </p>
          </Hidden> */}
        </div>
        <div className={classes.imgSection}>
          <img src={bgimg} alt="img" className={classes.img} />
        </div>
      </div>
    </div>
  );
}

export default Hero;
