import React from "react";
import { 
  Hero,
  Slider,
  Features
 } from './components';

function Home() {
  return (
    <div>
      <Hero />
      <Features />
      {/* <Slider /> */}
    </div>
  );
}

export default Home;
