import React, { useState } from "react";
import logo from "assets/Group 620.svg";
import { aboutUs, socialLinks, team } from "utils/constant";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import emailImg from "assets/email.png";
import phoneImg from "assets/phone.png";

function Footer() {
  const classes = useStyles();
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });
  const submitForm = () => {};
  return (
    <div className={classes.root}>
      <Grid container justify="space-between" spacing={3}>
        <Grid item md={3} sm={6} xs={12} className={classes.aboutSection}>
          <div className={classes.footerBox1}>
            <img className={classes.logo} src={logo} alt="logo" />
            <Typography className={classes.title} variant="h6">
              WEARHOUSE
            </Typography>
          </div>
          {/* <div className={classes.form}>
            <input
              type="text"
              name="name"
              value={state.name}
              onChange={handleChange}
              placeholder="Name"
              className={classes.input}
            />
            <input
              type="email"
              name="email"
              value={state.email}
              onChange={handleChange}
              placeholder="Email"
              className={classes.input}
            />
            <textarea
              name="message"
              value={state.message}
              onChange={handleChange}
              placeholder="Message"
              className={classes.msginput}
            ></textarea>
            <button onClick={submitForm} className={classes.btn}>
              Submit
            </button>
          </div> */}
        <Typography variant="h5" className={classes.contactTitle}>
          Contact Us
        </Typography>
        
          <Grid
          container
          item
          md={12}
          sm={12}
          xs={12}
          style={{ textAlign: "center", marginTop: "25px" }}
        >
          <br/>
          <Typography
                variant="h6"
                className={classes.teamName}
                style={{ display: "inline", marginRight: 10 }}
              >
               <img alt="img"
                    className={classes.email} src={emailImg} />

</Typography>
          <a style={{ display: "inline", marginRight: 10 }} href="mailto:theangcomapny@gmail.com">{" "} theangcomapny@gmail.com</a> 
<div style={{marginTop: 25}}>
          <Typography
                variant="h6"
                className={classes.teamName}
                style={{ display: "inline", marginRight: 10 }}
              >
               <img alt="img"
                    className={classes.email} src={phoneImg} />

</Typography>
          <p style={{ display: "inline", marginRight: 10 }}>{" "} 9833880186 </p> 
          </div>    
        </Grid>
       
        
         
        </Grid>
        <Grid
          container
          item
          md={3}
          sm={6}
          xs={12}
          className={classes.aboutSection}
        >
          <Grid item md={12} xs={12} sm={12}>
            <Typography variant="h5" className={classes.teamTitle}>
              Team
            </Typography>
          </Grid>
          {team.map((item, i) => (
            <Grid
              item
              md={6}
              xs={6}
              sm={6}
              key={i}
              style={{ textAlign: "center" }}
            >
              <img className={classes.teamImg} src={item.img} alt="img" />
              <Typography variant="h6" className={classes.teamName}>
                {item.name}
              </Typography>
            </Grid>
          ))}
          <Grid
            item
            container
            md={12}
            xs={12}
            sm={12}
            style={{ marginBottom: 20 }}
          />
          <Grid item container spacing={3} md={12} sm={12} xs={12}>
          <Grid
                item
                md={1}
                sm={1}
                xs={1}
                style={{ textAlign: "center" }}
              >
                </Grid>
            {socialLinks.map((item, i) => (
              <Grid
                item
                md={2}
                sm={2}
                xs={2}
                key={i}
                style={{ textAlign: "center" }}
              >
                <a href={item.to}>
                  <img
                    src={item.icon}
                    alt="img"
                    className={classes[item.name]}
                  />
                </a>
              </Grid>
            ))}
            <Grid
                item
                md={1}
                sm={1}
                xs={1}
                style={{ textAlign: "center" }}
              >
                </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} style={{ marginTop: 20,textAlign: "center" }}>
            <Link to="/terms-of-service">
              <Typography
                variant="h6"
                className={classes.teamName}
                style={{ display: "inline", marginRight: 10 }}
              >
                Terms of Use
              </Typography>
            </Link>
            <Link to="/privacy-policy">
              <Typography
                variant="h6"
                className={classes.teamName}
                style={{ display: "inline" }}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item md={3} sm={12} xs={12} className={classes.aboutSection}>
          <Typography className={classes.teamTitle} variant="h6">
            {aboutUs.title}
          </Typography>
          <Typography variant="h6" className={classes.aboutusContent}>
            {aboutUs.content}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
