import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  index: {
    background: "#0BB2B4",
    marginRight: 20,
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 20,
    width: 20,
    borderRadius: 40,
    color: "white",
    [theme.breakpoints.down('xl')]: { fontSize: 22, width: 27, height: 27},
    [theme.breakpoints.down('lg')]: { fontSize: 16, width: 25, height: 25},
    [theme.breakpoints.down('md')]: { fontSize: 14, width: 22, height: 22},
    [theme.breakpoints.down('sm')]: { fontSize: 12, width: 20, height: 20},
  },
  root: {
    padding: "8%",
    marginTop: 50,
    width: '100%',
    margin: 'auto',
    justifyContent: 'flex-start',
    [theme.breakpoints.down("xl")]: {
      marginTop: 50,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 100,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 50,
      justifyContent: 'center',
    },
  },
  title: {
    font: "normal normal normal 61px/83px DM Serif Display",
    marginBottom: 20,
  },
  options: {
    fontSize: 18,
    [theme.breakpoints.down("xl")]: {
      fontSize: 35,
      marginTop: -10,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 25,
      marginTop: -5,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      marginTop: -5,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: -5,
    },
    color: "#131B23",
  },
  smImg: {
    width: "80%",
    margin: "auto",
  },
  bgImg: {
    width: "100%",
    margin: "auto",
  },
  storeBtn: {
    [theme.breakpoints.down('lg')]: { 
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      marginTop: 0,
    },
  },
  margin: {
    [theme.breakpoints.down('sm')]: { 
      marginBottom: 100,
      height: 50
     }
  },
  icon: { width: "100%" },
}));
export default useStyles;
