import React from "react";
import { Paper, makeStyles } from "@material-ui/core";
import { termsAndCondition } from "utils/termsAndCondition";

const useStyles = makeStyles((theme) => ({
  body: {
    background: "#DBDBDB",
    paddingBottom: 50,
  },
  root: {
    width: "70%",
    margin: "auto",
    padding: 12,
    paddingBottom: 24,
    marginTop: -150,
    paddingLeft: 24, 
    paddingRight: 24,
    [theme.breakpoints.down("md")]: { width: "80%" },
    [theme.breakpoints.down("sm")]: { width: "85%" },
    [theme.breakpoints.down("xs")]: { width: "90%" },
  },
  blackBox: {
    width: "100%",
    height: 300,
    backgroundImage: "linear-gradient(to bottom, #131B23 , #DBDBDB)",
  },
  text: {
    textAlign: "center",
  },
}));

function TermsAndCondition() {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.blackBox} />
      <Paper className={classes.root}>
        <h1 className={classes.text}>Terms &amp; Conditions</h1>
        <p>{termsAndCondition.text1}</p>
        <p>{termsAndCondition.text2}</p>
        <p>{termsAndCondition.text3}</p>
        <p>{termsAndCondition.text4}</p>
        <p>{termsAndCondition.text5}</p>
        <p>{termsAndCondition.text6}</p>
        <p>{termsAndCondition.text7}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: termsAndCondition.text8,
          }}
        />
        <p><strong>{termsAndCondition.text9}</strong></p>
        <p>{termsAndCondition.text10}</p>
        <p>{termsAndCondition.text11}</p>
        <p><strong>{termsAndCondition.text12}</strong></p>
        <p>{termsAndCondition.text13}</p>
      </Paper>
    </div>
  );
}

export default TermsAndCondition;
