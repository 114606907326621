import { Home, PrivacyPolicy, TermsAndCondition } from "Pages";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/terms-of-service",
    component: TermsAndCondition,
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: true,
  },
];
export default routes;
